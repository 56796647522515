<template>
<div>
  <Header/>              
  <section style="margin-top:5rem" class="p-1">
    <SkeletonDetailExperience v-if="isLoadingProduct"/>           
    <b-card v-if="productDetail" no-body style="max-width: 100%; height: 600px;" >
      <b-row >
        <div class="col-xs-4 col-md-4">
          <b-card-img style="height:600px;" :src="imgUrl + productDetail.coverImage" :alt="`Photo ${productDetail.name}`" />                
        </div>
        <div class="col-xs-5 col-md-5">
          <h4 class="mt-2">{{ productDetail.name }}</h4>            
            <b-badge variant="primary" class="mb-2">Category:  {{ productDetail.categoryName }}</b-badge>
            <b-card-text class="text-justify scrolling-wrapper-description border p-1" v-if="isHtml">
              <div v-html="productDetail.description" class="scrollbar-vertical"></div>
            </b-card-text>
        </div>  
        <div class="col-xs-3 col-md-3">
          <div class="p-1 float-right">
            <b-button variant="warning" size="sm"  @click="$router.back()">            
                <feather-icon icon="ArrowLeftIcon" size="16" /> Back
              </b-button>
          </div>
          <br><br><br>
          <div class="p-1 ">
            <b-alert variant="primary" show >
              <div class="alert-body ">
                <span><strong>Indicate a qty</strong> and add to the cart.</span>
              </div>
            </b-alert>
          </div>          
          <div class="p-1">
            <ProductDetails :productDetails="productDetail.detail" :product="productDetail" :isSingle="true" v-if="productDetail.detail.length > 1 "/>    
            <SingleDetail :singleDetail="productDetail.detail[0]" :product="productDetail" :isSingle="true" v-else/>
          </div>

          <div class="d-flex justify-content-between p-1">
            <div>
              <b v-if="productDetail.total !=='0.00'" class="text-total-product"> Total: ${{ productDetail.total }}  USD</b>              
            </div>
            
            <div>
              <b-button variant="primary" class="btn-cart"  @click="handlerAddToCart(productDetail)">            
                <feather-icon icon="ShoppingCartIcon" size="16" /> 
              </b-button>
            </div>
          </div>         
        </div>      
      </b-row>
    </b-card>      
  </section>  
  <!-- <Footer/>     -->
</div>
</template>

<script>
import { mapActions, mapMutations, mapGetters, mapState } from 'vuex'
import Ripple from 'vue-ripple-directive'
import { overWriteAxiosHeaders, toJson } from '@/helpers/helpers'

import Header from '@/modules/shop/components/home/Header'
import Footer from '@/modules/shop/components/home/Footer'
import ProductDetails from '@/modules/shop/components/experiences/ProductDetails'
import SingleDetail from '@/modules/shop/components/experiences/SingleDetail'

import SkeletonDetailExperience from '@/modules/shop/components/experiences/skeletons/SkeletonDetailExperience'
import { market } from '@/modules/shop/mixins/market'

export default {
  mixins: [ market ], 
  directives: {
    Ripple,
  },
  components: {
    Header,
    Footer,
    ProductDetails,
    SingleDetail,
    SkeletonDetailExperience
  },
  props:{
    slug:{
      type: String,
      required: true
    }
  },
  created() {
    this.$store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', true)
    // this.lastPath = this.$router.options.history.state.back
    if(!this.queryParams){
      this.$router.push({name: 'not-authorized' })
      return 
    }
    this.setProductDetail(null)      
    this.slugFromUrl = this.$route.params.slug
    this.makePayloadFromRouteParams( this.slugFromUrl )    
  },
  destroyed() {
    this.$store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', true)
  },
  async mounted(){
    if( this.payloadPeticion ){
      this.isLoadingProduct = true
      if(this.queryParams){
        await overWriteAxiosHeaders( this.queryParams )
        const product = await this.fetchProductDetail( this.payloadPeticion )
        this.setProductDetail(product)  
      }
      this.isLoadingProduct = false

    }
  },
  data() {
    return {
      slugFromUrl: '',
      payloadPeticion: null,
      imgUrl: process.env.VUE_APP_BASE_URL_SERVER,
      isLoadingProduct: false,
      lastPath: null,
    }
  },
  computed:{
    ...mapState('shop',['cart']),    
    ...mapState('products',['productDetail']),               
    ...mapGetters('products',['filteredProducts']), 
    ...mapState('auth',['queryParams']),
    prevRoutePatch () {
      return this.lastPath ? this.lastPath : '/home'
    },               
    slugUrl() {
      return this.slugFromUrl
    },
  
    isHtml(){
      return /<([A-Za-z][A-Za-z0-9]*)\b[^>]*>(.*?)<\/\1>/.test( this.productDetail.description )       
    }          
  },
  methods: {
    ...mapActions('products',['fetchProducts','fetchProductDetail']),
    ...mapMutations('products',['setProductDetail','resetQtySingleProductDetail']),     
    ...mapMutations('shop',['addProductInCart','calculateTotalBreakdown']),    
    makePayloadFromRouteParams( cadena ){
      const endPoints = ["products", "events", "packages"]
      const arreglo = cadena.split("-", 2)
      const endPoint = arreglo[0]
      const idProducto = parseInt(arreglo[1])
      const isValidEndpoint = endPoints.includes(endPoint)
      if( isValidEndpoint ){
        this.payloadPeticion = { endPoint, idProducto }
      }
      return this.payloadPeticion 
    },
    async handlerAddToCart(product){
      let item = toJson({...product})//spread
      const productsAddCart = this.generateProductsToAddCart(item) // desde el mixin
      //ciclo asyncrono para ir añadiendo al cart
      productsAddCart.forEach( async productCart => {          
        const { qty } = productCart.detailSelected //tomo su cantidad
        const productFormatted = this.formatProductBeforeAddingToCart(productCart) //desde el mixin, formateo el item
        const productIsRepeated = this.existsInCart( productFormatted )
        if( !productIsRepeated ){ // si producto no se repite         
          this.addProductInCart(productFormatted) // agrego el producto formateado
          await this.setConfigBlackoutDatesProduct(productFormatted, qty)  // desde el mixin market 
        } else {
          await this.addOneMoreQty(productFormatted)
        }
      })  
      
      this.resetQtySingleProductDetail() // reseteo los qty detail del producto                           
    },
    async addOneMoreQty(product){
      const { modelType, detailId, qty }  = product   
      const prodInCart = this.cart.find( product => product.detailId === detailId && product.modelType === modelType )
      prodInCart.qty = prodInCart.qty + qty//aumento la cantidad
      this.calculateTotalBreakdown()
      await this.qtyHasChanged(prodInCart) // desde el mixim market
    },
    
  },
  watch:{
    slug(){      
      this.unsetDetailProduct()      
      this.slugFromUrl = this.$route.params.slug
      this.makePayloadFromRouteParams( this.slugFromUrl ) 
      if( this.payloadPeticion ){
        this.fetchProductDetail( this.payloadPeticion )  
      }
    }   
  }  
}
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce-details.scss";
  .scrollbar-vertical{    
    height: 450px;
    overflow: auto;  
    padding-inline-end: .5rem;      
  }  
  .scrollbar-vertical-2{    
    height: 350px;
    overflow: auto;  
    padding-inline-end: .5rem;      
  }
  .scrollbar-vertical::-webkit-scrollbar-track, .scrollbar-vertical-2::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    background-color: #F5F5F5;
  }
  
  .scrollbar-vertical::-webkit-scrollbar, .scrollbar-vertical-2::-webkit-scrollbar{
    width: 6px;
    background-color: #F5F5F5;
  }
  
  .scrollbar-vertical::-webkit-scrollbar-thumb, .scrollbar-vertical-2::-webkit-scrollbar-thumb{
    background-color: #ff9f43;	
    background-image: -webkit-linear-gradient(45deg,rgba(255, 255, 255, .2) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, .2) 50%, rgba(255, 255, 255, .2) 75%, transparent 75%, transparent)
  }

  .scrolling-wrapper-description{
    overflow-x: auto;
  }

  .scrolling-wrapper-description::-webkit-scrollbar {
    height: 6px;
    background-color: #F5F5F5;
  }

  .scrolling-wrapper-description::-webkit-scrollbar-thumb{
    background-color: #ff9f43;	
    background-image: -webkit-linear-gradient(45deg,rgba(255, 255, 255, .2) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, .2) 50%, rgba(255, 255, 255, .2) 75%, transparent 75%, transparent)
  }
  .detail-item{
    font-size: .8rem;
  }
  .badge-price{
    font-size: .65rem;
  }
  .text-total-product{
    text-decoration: underline solid #FFD700 3px;    
  }
</style>